import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "item-detail frappe-control" }
const _hoisted_2 = {
  key: 0,
  class: "d-fleex flex-row flex"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.d && $data.d.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.d, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "m-2 p-2 address-box text-center",
              key: item.name
            }, [
              _createElementVNode("h5", null, _toDisplayString(item.name), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString(item.value), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "attribute-list-template frappe-control" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.attr_list && $setup.attr_list.length != 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.attr_list, (attr, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "m-2 address-box flex-fill",
              key: attr.attr_name
            }, [
              _createElementVNode("h5", null, [
                _createTextVNode(_toDisplayString(attr.attr_name) + " ", 1 /* TEXT */),
                (attr.attr_values_link)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: '/app/Form/' + encodeURIComponent(attr.doctype) +'/' + encodeURIComponent(attr.attr_values_link),
                      class: "btn btn-default btn-xs pull-right",
                      style: {"margin-top":"-3px","margin-right":"-5px"}
                    }, " Edit ", 8 /* PROPS */, _hoisted_3))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: $event => ($setup.addAttributeMapping(attr.doctype, index)),
                      class: "btn btn-default btn-xs pull-right",
                      style: {"margin-top":"-3px","margin-right":"-5px"}
                    }, " Add ", 8 /* PROPS */, _hoisted_4))
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attr.attr_values, (value) => {
                return (_openBlock(), _createElementBlock("p", {
                  class: "text-muted",
                  key: value.name
                }, _toDisplayString(value.attribute_value), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_5, "Add an attribute above."))
  ]))
}
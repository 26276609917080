import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d9177b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "root" }
const _hoisted_2 = { class: "table table-sm table-bordered" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items.attributes, (x) => {
          return (_openBlock(), _createElementBlock("th", {
            key: x,
            class: "equal-width"
          }, [
            (x != "Weight")
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(x), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString("Weight ( In Kg's )"), 1 /* TEXT */))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items.items, (item, index) => {
        return (_openBlock(), _createElementBlock("tr", { key: index }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (value, key) => {
            return (_openBlock(), _createElementBlock("td", {
              key: key,
              class: "equal-width"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass($setup.get_input_class(key, index))
              }, null, 2 /* CLASS */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 512 /* NEED_PATCH */))
}
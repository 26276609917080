import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { ref: "root" }
const _hoisted_2 = {
  key: 0,
  class: "table table-sm table-bordered"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.items && $setup.items.items && $setup.items.items.length > 0)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items.attributes, (x) => {
              return (_openBlock(), _createElementBlock("th", { key: x }, [
                _createElementVNode("div", null, _toDisplayString(x), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items.items, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", null, _toDisplayString(item.accessory), 1 /* TEXT */),
              ($setup.items.is_set_item)
                ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(item[$setup.items.set_attr]), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              _createElementVNode("td", null, [
                _createTextVNode(_toDisplayString(item.major_colour) + " ", 1 /* TEXT */),
                (item.major_attr_value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(item.major_attr_value) + ")", 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("div", {
                  class: _normalizeClass($setup.get_input_class(item.major_colour, index, 'accessory_colour'))
                }, null, 2 /* CLASS */)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("div", {
                  class: _normalizeClass($setup.get_input_class(item.major_colour, index, 'cloth_type'))
                }, null, 2 /* CLASS */)
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ItemLotFetcher"], {
      items: $setup.items,
      "other-inputs": $setup.other_inputs,
      "table-fields": $setup.table_fields,
      "allow-secondary-qty": false,
      args: $setup.args,
      edit: $setup.docstatus == 0,
      "qty-fields": $setup.qty_fields,
      "validate-qty": false,
      onItemadded: $setup.updated,
      onItemupdated: $setup.updated,
      onItemremoved: $setup.updated
    }, null, 8 /* PROPS */, ["items", "other-inputs", "table-fields", "args", "edit", "qty-fields"])
  ]))
}
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-592fb9e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item frappe-control" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ItemLotFetcher"], {
      items: $setup.items,
      "other-inputs": $setup.otherInputs,
      "table-fields": $setup.table_fields,
      "allow-secondary-qty": true,
      args: $setup.args,
      edit: $setup.docstatus == 0,
      "validate-qty": true,
      enableAdditionalParameter: true,
      validate: $setup.validate,
      onItemadded: $setup.updated,
      onItemupdated: $setup.updated,
      onItemremoved: $setup.updated
    }, null, 8 /* PROPS */, ["items", "other-inputs", "table-fields", "args", "edit"])
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bom-attribute-mapping-template frappe-control" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.bom_attr_list && _ctx.bom_attr_list.length != 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bom_attr_list, (bom_item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "m-2 address-box flex-fill",
              key: bom_item.attr_name
            }, [
              _createElementVNode("h5", null, [
                _createTextVNode(_toDisplayString(bom_item.bom_item + ' ' + bom_item.bom_attr_mapping_based_on + ' Mapping') + " ", 1 /* TEXT */),
                (bom_item.bom_attr_mapping_link)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: '/app/Form/' + encodeURIComponent(bom_item.doctype) +'/' + encodeURIComponent(bom_item.bom_attr_mapping_link),
                      class: "btn btn-default btn-xs pull-right",
                      style: {"margin-top":"-3px","margin-right":"-5px"}
                    }, _toDisplayString(_ctx.__("Edit")), 9 /* TEXT, PROPS */, _hoisted_3))
                  : _createCommentVNode("v-if", true)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bom_item.bom_attr_mapping_list, (value) => {
                return (_openBlock(), _createElementBlock("p", {
                  class: "text-muted",
                  key: value.name
                }, _toDisplayString(value.product_attribute + ' - ' + value.bom_item_attribute), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_4, "Add a BOM above."))
  ]))
}